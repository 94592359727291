// Copyright 2022-2024 - Hewlett Packard Enterprise Company
import React from 'react';
import { Box, Text } from 'grommet';
import LoadingSpinner from './LoadingSpinner';

const LoadingSpinnerWithText = ({ size, label, orientation }) => (
  <Box
    align="center"
    justify="center"
    width={orientation === 'vertical' ? size : null}
    direction={orientation === 'horizontal' ? 'row' : 'column'}
    gap={orientation === 'horizontal' ? 'small' : null}
  >
    <Box align="center" justify="center" width={size} height={size}>
      <LoadingSpinner />
    </Box>

    {label && (
      <Text size="xsmall" type="text">
        {label}
      </Text>
    )}
  </Box>
);

export default LoadingSpinnerWithText;

// Copyright 2022-2024 - Hewlett Packard Enterprise Company
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
/* eslint-disable import/no-unresolved */
import resourcesToBackend from 'i18next-resources-to-backend';

// when REACT_APP_ADD_NON_PROD_I18N_LANGS is "true", COM will be able to support
// all languages in nonProdLangs array, in a nonprod environment.
const NON_PROD_I18N_LANGS = window.indigo.REACT_APP_ADD_NON_PROD_I18N_LANGS;

const supportedLngs = ['en-US', 'ja-JP'];
// The following are languages that should not be delivered to customers
// es-ES - Spanish to test local to indigo non-English l10n
// de-DE - German from Stevedore added during docker build
const nonProdLangs = ['es-ES', 'de-DE'];
if (NON_PROD_I18N_LANGS && NON_PROD_I18N_LANGS === 'true') {
  supportedLngs.push(...nonProdLangs);
}

i18n
  .use(
    resourcesToBackend(async (language, namespace, callback) => {
      try {
        // this will ensure only the requested locale files will be imported
        // learn more: https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
        const resources = await import(
          /* webpackChunkName: "locales/[request]" */
          `./locales/${language}/${namespace}.json`
        );
        callback(null, resources);
      } catch (error) {
        callback(error, null);
      }
    }),
  )
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-US',
    supportedLngs,
    detection: {
      // a different detection order and caches can be found here https://github.com/glcp/frontend/blob/mainline/ui/src/i18n.js
      order: ['cookie', 'localStorage', 'navigator', 'htmlTag'],
      // after the language code was detected, it will be stored in the localStorage and cookie
      caches: ['cookie', 'localStorage'],
    },
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;

// Copyright 2021-2024 - Hewlett Packard Enterprise Company
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { UserManager, Log } from 'oidc-client';
import { oidcConfig } from '../constants';
import {
  SESSION_KEY,
  CUSTOMER_ID_KEY,
  TENANT_ACID_KEY,
  DEV_ACID_KEY,
  LOG_LEVEL,
  LOG_LEVELS,
} from '../constants/authConst';
import {
  initUnauthorizedInterceptor,
  initAuthInterceptors,
  installMessagingToken,
  forceNewWebsocket,
  getTokenFromStorage,
} from '../utils/apiHelpers';
import useIdle from '../hooks/useIdle';
import { isDevEnvAuth } from '../utils/authHelpers';
import { queryUserPermissions } from '../actions/Api';

// local storage values are represented as strings. Assume the input is a string,
// if it's falsey remove it from storage
const setStorageValue = (key, value) => {
  if (key) {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key, value);
    }
  }
};

// oidc-client Logger
Log.logger = console;
if (LOG_LEVEL && LOG_LEVELS.includes(LOG_LEVEL)) {
  Log.level = Log[LOG_LEVEL];
} else {
  Log.level = Log.ERROR;
}

// eslint-disable-next-line no-console
const debugLog = msg => LOG_LEVEL === 'DEBUG' && console.log(msg);

const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const AuthContext = React.createContext({});
export const AuthConsumer = AuthContext.Consumer;

export default function AuthProvider({ children }) {
  const [token, setToken] = useState(() => {
    let token = '';

    // since the user manager uses session storage, it's only expected to have a value
    // for the current tab, like in a browser page refresh scenario
    // in the case where the user navigates back to GLP, switches workspaces and relaunches COM,
    // the token in storage will need to be discarded in favor of the new one
    const storedToken = getTokenFromStorage(SESSION_KEY);
    if (storedToken) {
      token = storedToken;
    }

    return token;
  });
  const [devAcid, setDevAcid] = useState(() => {
    let devAcid = 'aci1';

    // to enable syncing the active customer id and tenant selection across tabs, local storage
    // is used to persist the current state and broadcast changes to other tabs
    // the dev acid is only used in the vagrant env where COM is not integrated with GLP auth
    const storedDevAcid = localStorage.getItem(DEV_ACID_KEY);
    if (isDevEnvAuth() && storedDevAcid) {
      devAcid = storedDevAcid;
    }

    return devAcid;
  });
  const [tenantAcid, setTenantAcid] = useState(() => {
    let tenantAcid;

    // the tenant selection should be synchronized across tabs- switching should force
    // other tabs to update with the tenant selected in the other tab. This means using
    // local storage to communicate those changes across tabs, and when opening new tabs
    const storedTenantAcid = localStorage.getItem(TENANT_ACID_KEY);
    if (storedTenantAcid) {
      tenantAcid = storedTenantAcid;
    }

    return tenantAcid;
  });
  const [axiosInit, setAxiosInit] = useState(false);
  const [idleTimeout, setIdleTimeout] = useState(60000);
  const refreshing = useRef(false);

  // This object stores data in "compute.group.read": true/false format
  // To check for a specific permission, use userPermissions['compute.group.read']
  const [userPermissions, setUserPermissions] = useState({});

  const userManager = useRef(null);
  // only initialize the user manager once to avoid repeated API calls
  if (userManager.current === null) {
    userManager.current = new UserManager({ ...oidcConfig });
  }

  const isIdle = useIdle(idleTimeout);

  const updateToken = useCallback(newToken => {
    const parsedToken = parseJwt(newToken);
    const msSinceEpochExpiry = parsedToken.exp * 1000;
    const msSinceEpochIssued = parsedToken.iat * 1000;
    const msExpiryTime = msSinceEpochExpiry - msSinceEpochIssued;
    setIdleTimeout(msExpiryTime);

    installMessagingToken(newToken);

    // save the token after it has been installed
    setToken(newToken);
  }, []);

  const updateTenantAcid = useCallback(
    (newTenantAcid, skipStorageUpdate = false) => {
      if (newTenantAcid !== tenantAcid) {
        setTenantAcid(newTenantAcid);

        // if the update is originating from listening to a storage event, we don't want to
        // update the storage again because it would generate redundant events for other tabs
        if (!skipStorageUpdate) {
          setStorageValue(TENANT_ACID_KEY, newTenantAcid);
        }

        forceNewWebsocket();
      }
    },
    [tenantAcid],
  );

  // this is only for environments not integrated with GLP auth, for the MSP feature
  const updateDevAcid = useCallback(
    (newDevAcid, skipStorageUpdate = false) => {
      if (isDevEnvAuth() && newDevAcid !== devAcid) {
        setDevAcid(newDevAcid);

        // if the update is originating from listening to a storage event, we don't want to
        // update the storage again because it would generate redundant events for other tabs
        if (!skipStorageUpdate) {
          setStorageValue(DEV_ACID_KEY, newDevAcid);
        }

        if (tenantAcid !== null) {
          // if the tenant acid needs to be reset, it will reopen the websocket
          updateTenantAcid(null, skipStorageUpdate);
        } else {
          forceNewWebsocket();
        }
      }
    },
    [devAcid, tenantAcid, updateTenantAcid],
  );

  useEffect(() => {
    initUnauthorizedInterceptor();

    // initialize axios interceptors to use values in session storage
    initAuthInterceptors(SESSION_KEY);

    setAxiosInit(true);
  }, []);

  // This useEffect should also get called whenever there is a change
  // in user's permissions. (CCSE-35382 - Wire up Eureka to inform UI).
  // For now, it is only called when auth token is updated.
  // Make sure to check for token && wait for axios initialization
  // otherwise call may fail with unauthorized error.
  useEffect(() => {
    if (token && axiosInit) {
      queryUserPermissions()
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .then(value => {
          const permissions = {};
          if (value?.data?.permissionEnforcements) {
            value.data.permissionEnforcements.forEach(permission => {
              permissions[permission.slug] = permission.granted;
            });
          }
          setUserPermissions(permissions);
        });
    }
    // Re-render on tenantAcid change so we get latest permissions.
  }, [axiosInit, token, tenantAcid]);

  const getUser = useCallback(() => userManager.current.getUser(), []);

  const getUserName = useCallback(async () => {
    const user = await userManager.current.getUser();
    let userName = '';
    if (user) {
      const parsedToken = parseJwt(user.access_token);
      userName = parsedToken.givenName ? parsedToken.givenName : '';
    }
    return userName;
  }, []);

  const getUserEmail = useCallback(async () => {
    const user = await userManager.current.getUser();
    let userEmail = '';
    if (user) {
      const parsedToken = parseJwt(user.access_token);
      userEmail = parsedToken.sub ? parsedToken.sub : '';
    }
    return userEmail;
  }, []);

  // Return a string representing the cid stored inside the access token.
  // Note: Do not use the access token directly from sessionStorage
  // since they may be different while the session is being
  // established or re-established (i.e. switching account).
  const getCid = useCallback(async () => {
    let cid = '';
    const user = await userManager.current.getUser();
    if (user) {
      const parsedToken = parseJwt(user.access_token);
      cid = parsedToken.user_ctx;
      cid = cid === null || cid === undefined ? '' : cid;

      // The cid parameter is a CCS concept. We fake one for
      // local development when we are not pointing at CCS Auth server
      if (!cid && isDevEnvAuth()) {
        cid = devAcid;
      }
    }
    return cid;
  }, [devAcid]);

  const getEffectiveAcid = useCallback(async () => {
    // if in the tenant context, return the tenant acid
    if (tenantAcid) {
      return tenantAcid;
    }

    // otherwise, return the primary acid for the session
    return getCid();
  }, [tenantAcid, getCid]);

  const isAuthenticated = useCallback(() => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(SESSION_KEY));
    return !!oidcStorage && !!oidcStorage.access_token;
  }, []);

  const login = useCallback(({ cid, workspaceId }) => {
    // communicate the account change event to other open tabs via local storage
    localStorage.setItem(CUSTOMER_ID_KEY, cid);
    userManager.current.signinRedirect({
      extraQueryParams: { cid, workspace_id: workspaceId },
      useReplaceToNavigate: true,
    });
  }, []);

  const logout = useCallback(async () => {
    await userManager.current.removeUser();
    await userManager.current.clearStaleState();

    localStorage.removeItem(CUSTOMER_ID_KEY);
    if (isDevEnvAuth()) {
      localStorage.removeItem(DEV_ACID_KEY);
    }
    localStorage.removeItem(TENANT_ACID_KEY);

    // notify the logout
    localStorage.setItem(SESSION_KEY, 'logout');
    localStorage.removeItem(SESSION_KEY);

    await userManager.current.signoutRedirect({
      extraQueryParams: {
        TargetResource: oidcConfig.post_logout_redirect_uri,
        InErrorResource: oidcConfig.post_logout_redirect_uri,
      },
    });
  }, []);

  // receive callback from idp after signinRedirect
  const signinRedirectCallback = useCallback(async () => {
    const user = await userManager.current.signinRedirectCallback();
    if (user) {
      updateToken(user.access_token);
    }
  }, [updateToken]);

  const renewToken = useCallback(async () => {
    let user = await userManager.current.getUser();

    if (user && user.refresh_token) {
      debugLog(`Old refresh token: ${user.refresh_token}`);
      debugLog(`Old access token:
        ${user.access_token.substring(0, 10)}...${user.access_token.slice(
          -10,
        )}`);
      // eslint-disable-next-line
      await userManager.current._useRefreshToken({
        refresh_token: user.refresh_token,
      });
      refreshing.current = false;
      user = await userManager.current.getUser();
      if (user) {
        debugLog(`New refresh token: ${user.refresh_token}`);
        debugLog(`New access token:
          ${user.access_token.substring(0, 10)}...${user.access_token.slice(
            -10,
          )}`);
        updateToken(user.access_token);
      }
    } else {
      throw new Error('No refresh token present, unable to renew tokens');
    }
  }, [updateToken]);

  const addAccessTokenExpiring = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('token expiring...');

    // If user is Idle for length idleTimeout: logout user before renewing token.
    if (isIdle) {
      // eslint-disable-next-line no-console
      console.log('User idle too long, logged out');
      logout();
    }

    // This event is fired once every 5 seconds. If we are
    // already in the process of refreshing, ignore the event.
    if (!refreshing.current) {
      refreshing.current = true;
      // use refresh token to try renewing access token
      renewToken().catch(err => {
        refreshing.current = false;
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('refreshing expiring token...');
    }
  }, [isIdle, logout, renewToken]);

  const addAccessTokenExpired = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('token expired');
    // refresh token has a longer lifetime and may still be valid
    // try to renew access with refresh token
    if (!refreshing.current) {
      refreshing.current = true;
      renewToken().catch(err => {
        refreshing.current = false;
        // eslint-disable-next-line no-console
        console.error(err);
        logout();
      });
    }
  }, [logout, renewToken]);

  useEffect(() => {
    const currentUserManager = userManager.current;
    currentUserManager.events.addAccessTokenExpiring(addAccessTokenExpiring);
    currentUserManager.events.addAccessTokenExpired(addAccessTokenExpired);

    return () => {
      currentUserManager.events.removeAccessTokenExpiring(
        addAccessTokenExpiring,
      );
      currentUserManager.events.removeAccessTokenExpired(addAccessTokenExpired);
    };
  }, [addAccessTokenExpiring, addAccessTokenExpired]);

  const contextObject = useMemo(
    () => ({
      getCid,
      getUser,
      getUserName,
      getUserEmail,
      userPermissions,
      isAuthenticated,
      login,
      logout,
      signinRedirectCallback,
      token,
      devAcid,
      updateDevAcid,
      tenantAcid,
      updateTenantAcid,
      getEffectiveAcid,
      axiosInit,
    }),
    [
      axiosInit,
      devAcid,
      getCid,
      getEffectiveAcid,
      getUser,
      getUserEmail,
      getUserName,
      userPermissions,
      isAuthenticated,
      login,
      logout,
      signinRedirectCallback,
      tenantAcid,
      token,
      updateDevAcid,
      updateTenantAcid,
    ],
  );

  return (
    <AuthContext.Provider value={contextObject}>
      {children}
    </AuthContext.Provider>
  );
}

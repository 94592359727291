// Copyright 2021-2024 - Hewlett Packard Enterprise Company

/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import { getLocale } from '../utils/apiHelpers';

const WEBSOCKET_TICKET_URL = `${window.indigo.REACT_APP_COM_API_ROOT_URL}/api/ui/ticket`;
const WEBSOCKET_URL = window.indigo.REACT_APP_EUREKA_URL;

const WEBSOCKET_CONNECTING = 0;
const WEBSOCKET_OPEN = 1;

// Leave this here for dev and debug
const debug = false;
const debugLog = msg => {
  if (debug) {
    // eslint-disable-next-line no-console
    console.log(msg);
  }
};

class Messaging {
  constructor() {
    this.ws = null;
    this.connected = false;
    this.subscriptions = {};
    this.token = null;
    this.gettingTicket = false;
    this.loopID = null;
  }

  // Use the topic and resource id to create a key
  // into the subscriptions object for a subscription
  getSubscribeKey = (topic, id) => (id ? `${topic}+${id}` : topic);

  // Return the topic from a subscription key
  getTopicInSubscribeKey = key => key.split('+')[0];

  // Return the resource id from a subscription key
  getIdInSubscribeKey = key => {
    const substrings = key.split('+');
    if (substrings.length === 2) {
      return substrings[1];
    }
    return undefined;
  };

  createSubscriptionRequest = (op, topic, id) => {
    const request = {
      op,
      request: 'category',
      params: topic,
    };
    if (id) {
      request.id = id;
    }
    return JSON.stringify(request);
  };

  createSubscribeRequest = (topic, id) =>
    this.createSubscriptionRequest('start', topic, id);

  createUnsubscribeRequest = (topic, id) =>
    this.createSubscriptionRequest('stop', topic, id);

  onerror = error => {
    debugLog(
      `WebSocket error occurred: ${error} at ${new Date().toLocaleTimeString(
        getLocale(),
      )}`,
    );
    this.connected = false;
  };

  // Websocket is closed.
  onclose = e => {
    this.connected = false;
    this.ws = null;
    debugLog(
      `WebSocket is closed with code: ${
        e.code
      } at ${new Date().toLocaleTimeString(getLocale())}`,
    );
  };

  // Handler function when a message arrives from the server.
  // Look for all listeners subscribed to the topic and run the
  // callback function.
  onmessage = e => {
    let data = null;

    if (e && e.data) {
      debugLog(`message received: ${e.data}`);
      try {
        data = JSON.parse(e.data);
      } catch (pe) {
        debugLog(`Malformed message received: ${e.data}`);
      }
    }

    if (data && data.op) {
      const { op, result: topic, id } = data;
      if (['change', 'created', 'updated', 'deleted'].includes(op)) {
        const topicKey = this.getSubscribeKey(topic);
        const topicCallbacks = this.subscriptions[topicKey];
        const idKey = this.getSubscribeKey(topic, id);
        const idCallbacks = this.subscriptions[idKey];
        if (topicCallbacks) {
          topicCallbacks.forEach(callback => callback(data));
        }
        if (idCallbacks) {
          idCallbacks.forEach(callback => callback(data));
        }
        if (op === 'deleted' && idCallbacks) {
          // Remove a subscription to a deleted device
          // since Eureka doesn't support unsubscribing
          // after the server is deleted.
          delete this.subscriptions[idKey];
        }
      } else if (op === 'error') {
        const logId = id ? ` ${id}` : '';
        debugLog(`Received error op for ${topic}${logId}`);
        if (topic === 'invalid_session') {
          this.ws.close();
        }
      }
    }
  };

  // When a websocket is connected, send the 'set access token' message.
  // If ws remains open, send subscription messages to the server for
  // the pending subscriptions.
  onopen = () => {
    if (this.ws.readyState === WEBSOCKET_OPEN) {
      this.connected = true;
      debugLog(
        `WebSocket connected at ${new Date().toLocaleTimeString(getLocale())}`,
      );
      // Send the access token on the ws
      try {
        this.ws.send(
          JSON.stringify({
            op: 'set',
            request: 'authorization',
            params: this.token,
          }),
        );
      } catch (e) {
        debugLog(`Unable to send set token message: ${e}`);
        return;
      }

      if (this.ws && this.ws.readyState === WEBSOCKET_OPEN) {
        Object.keys(this.subscriptions).forEach(key => {
          const topic = this.getTopicInSubscribeKey(key);
          const id = this.getIdInSubscribeKey(key);
          try {
            const request = this.createSubscribeRequest(topic, id);
            this.ws.send(request);
          } catch (e) {
            const logId = id ? ` ${id}` : '';
            debugLog(
              `Unable to send subscription message for ${topic}${logId}: ${e}`,
            );
          }
        });
      }
    }
  };

  // This function is called to gracefully shutdown the
  // websocket and clean up our state. Called when the
  // user leaves indigo.
  disconnect = () => {
    debugLog('disconnect() called');
    this.ws && this.ws.close();
    this.ws = null;
    this.subscriptions = {};
  };

  // Check if we have a connected websocket. If not, attempt to create one
  // now if these conditions are met:
  // - an access token is installed
  // - a websocket has not been created yet or
  // - a websocket exists but not yet connected.
  checkAndConnect = async () => {
    if (this.token) {
      if (
        !this.ws ||
        (this.ws &&
          this.ws.readyState !== WEBSOCKET_CONNECTING &&
          this.ws.readyState !== WEBSOCKET_OPEN)
      ) {
        debugLog('checkAndConnect() called to create new WebSocket');

        if (!this.gettingTicket) {
          this.gettingTicket = true;
          axios
            .post(WEBSOCKET_TICKET_URL, null, { withCredentials: true })
            .then(
              response => {
                this.gettingTicket = false;
                this.ws = new WebSocket(WEBSOCKET_URL);
                this.ws.onopen = this.onopen;
                this.ws.onerror = this.onerror;
                this.ws.onmessage = this.onmessage;
                this.ws.onclose = this.onclose;

                // Disconnect gracefully before exiting
                window.onbeforeunload = this.disconnect;
              },
              error => {
                this.gettingTicket = false;
                // eslint-disable-next-line no-console
                console.log(error);
              },
            );
        }
      }
    }
  };

  /**
   * Self healing loop. Check every 15 seconds.
   * checkAndConnect() must be kept light weight to
   * avoid CPU consumption in each loop.
   */
  startLoop = () => {
    if (!this.loopID) {
      this.loopID = setInterval(this.checkAndConnect, 15000);
    }
  };

  // Add a subscription specified by the topic, callback, and
  // optionally an id.  If we have not sent a subscription
  // request to Eureka for the topic or id, send one.
  addSubscription(topic, callback, id) {
    const subscribeKey = this.getSubscribeKey(topic, id);
    if (!this.subscriptions[subscribeKey]) {
      this.subscriptions[subscribeKey] = [callback];
      if (this.ws && this.connected) {
        try {
          const request = this.createSubscribeRequest(topic, id);
          this.ws.send(request);
        } catch (e) {
          const logId = id ? ` ${id}` : '';
          debugLog(
            `Unable to send subscription message for ${topic}${logId}: ${e}`,
          );
        }
      }
    } else {
      this.subscriptions[subscribeKey].push(callback);
    }
  }

  // Remove a subscription identified by the topic, callback,
  // and optionally an id.
  removeSubscription(topic, callback, id) {
    const subscribeKey = this.getSubscribeKey(topic, id);
    if (
      this.subscriptions[subscribeKey] &&
      this.subscriptions[subscribeKey].length > 0
    ) {
      // remove callback from the listeners list
      this.subscriptions[subscribeKey] = this.subscriptions[
        subscribeKey
      ].filter(func => callback !== func);

      // if there is no more listener, unsubscribe topic from the server
      if (this.subscriptions[subscribeKey].length === 0 && this.connected) {
        delete this.subscriptions[subscribeKey];
        if (this.ws) {
          try {
            const request = this.createUnsubscribeRequest(topic, id);
            this.ws.send(request);
          } catch (e) {
            const logId = id ? ` ${id}` : '';
            debugLog(
              `Unable to send unsubscribe message for ${topic}${logId}: ${e}`,
            );
          }
        }
      }
    }
  }

  /**
   * Install or update an access_token. Access token must be installed
   * first before a websocket can be created. When the token needs to
   * be updated, a message is sent to the server with the new token
   * as the payload
   *
   * @param {String} token The access token
   */
  installToken = token => {
    if (this.token !== token) {
      debugLog('Messaging.intallToken() called');
      this.token = token;

      if (this.ws && this.connected) {
        // update websocket connection with new token
        try {
          this.ws.send(
            JSON.stringify({
              op: 'update',
              request: 'authorization',
              params: token,
            }),
          );
        } catch (e) {
          debugLog(`Unable to send update token message: ${e}`);
        }
      } else {
        this.checkAndConnect();
      }
    }
    this.startLoop();
  };

  /**
   * Subscribe a topic.
   *
   * @param {String} topic E.g. server, workflow
   * @param {Function} callback The callback function when message arrives
   * @param {String} id The id of a particular resource (optional)
   */
  subscribe = (topic, callback, id) => {
    const logId = id ? ` ${id}` : '';
    debugLog(`Messaging.subscribe(${topic}${logId}) called`);
    this.addSubscription(topic, callback, id);
    this.checkAndConnect();
  };

  /**
   * Unsubscribe a topic. This should be the same topic
   * and callback that was passed to subscribe()
   *
   * @param {String} topic E.g. server, workflow
   * @param {Function} callback The callback function when message arrives
   * @param {String} id The id of a particular resource (optional)
   */
  unsubscribe = (topic, callback, id) => {
    const logId = id ? ` ${id}` : '';
    debugLog(`Messaging.unsubscribed(${topic}${logId}) called`);
    this.removeSubscription(topic, callback, id);
  };

  /**
   * Clear all subscriptions. Called by RefreshProvierer.initRefresh().
   */
  clearSubscriptions = () => {
    this.subscriptions = {};
  };

  /**
   * Return the websocket connection state
   */
  isConnected = () => this.connected;

  closeAndReconnect = () => {
    if (this.isConnected()) {
      this.disconnect();
    }
    this.checkAndConnect();
  };
}

// Export a singleton
export const messaging = new Messaging();

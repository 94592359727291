// Copyright 2021-2024 - Hewlett Packard Enterprise Company

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthConsumer } from '../providers/AuthProvider';
import { isDevEnvAuth } from '../utils/authHelpers';
import { DEV_ACID_KEY, TENANT_ACID_KEY } from '../constants/authConst';

const CCS_LOGIN_URL = window.indigo.REACT_APP_CCS_LOGIN;
const APP_INSTANCE_ID = window.indigo.REACT_APP_CCS_APP_INSTANCE_ID;

export default function Login() {
  const history = useHistory();
  const location = useLocation();

  return (
    <AuthConsumer>
      {({ getUser, login, getCid, updateTenantAcid }) => {
        async function doLogin() {
          const params = new URLSearchParams(location.search);
          let cid = params.get('cid');
          const workspaceId = params.get('workspace_id');

          // always start navigation on MSP page
          updateTenantAcid(null);

          const user = await getUser();
          if (user) {
            // If cid is different than the one that is stored,
            // then login with the new cid. This happens when
            // the user switch account.
            if (cid && cid !== (await getCid())) {
              localStorage.clear(TENANT_ACID_KEY);
              localStorage.clear(DEV_ACID_KEY);
              login({ cid, workspaceId });
            } else {
              // user already logged in, redirect home
              history.replace('/');
            }
            return;
          }

          // The cid parameter is a GLP concept. We fake one for
          // local development when we are not pointing at a GLP auth server
          if (!cid && isDevEnvAuth()) {
            cid = 'aci1';
          }

          if (cid) {
            // begin oidc flow to get access token
            login({ cid, workspaceId });
          } else {
            // no cid query parameter present, redirect to GLP /login?app=<application_instance_id>
            // for customer login and to get cid parameter
            window.location.replace(`${CCS_LOGIN_URL}?app=${APP_INSTANCE_ID}`);
          }
        }

        doLogin();
      }}
    </AuthConsumer>
  );
}

// Copyright 2021-2024 - Hewlett Packard Enterprise Company

import { WebStorageStateStore } from 'oidc-client';

export const OIDC_AUTHORITY = window.indigo.REACT_APP_OIDC_AUTHORITY;
const OIDC_CLIENT_ID = window.indigo.REACT_APP_CCS_APP_INSTANCE_ID;
const POST_LOGOUT_URL = window.indigo.REACT_APP_CCS_LOGOUT;
export const DEV_AUTHORITY_URL = 'https://demo.duendesoftware.com';

export const SESSION_KEY = `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`;
export const CUSTOMER_ID_KEY = `${SESSION_KEY}:cid`;
export const TENANT_ACID_KEY = `${SESSION_KEY}:tenantAcid`;
export const DEV_ACID_KEY = `${SESSION_KEY}:devAcid`;
export const REDIRECT_URI_KEY = `${SESSION_KEY}:redirect-uri`;

export const LOG_LEVEL = window.indigo.REACT_APP_OIDC_LOG_LEVEL;
export const LOG_LEVELS = ['NONE', 'ERROR', 'WARN', 'INFO', 'DEBUG'];

export const OPENID_CONFIG_URL = `${OIDC_AUTHORITY}/.well-known/openid-configuration`;

const url = `${window.location.protocol}//${window.location.host}`;

export const oidcConfig = {
  authority: OIDC_AUTHORITY,
  client_id: OIDC_CLIENT_ID,
  redirect_uri: `${url}/auth`,
  response_type: 'code',
  post_logout_redirect_uri: POST_LOGOUT_URL,
  scope: 'openid profile email',
  silent_redirect_uri: `${url}/auth`,
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTime: 300,
  loadUserInfo: false,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  metadataSeed: {
    // ping deviates from the standard (ping_end_session_endpoint)
    end_session_endpoint: `${OIDC_AUTHORITY}/idp/startSLO.ping`,
  },
};

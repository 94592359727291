// Copyright 2021-2024 - Hewlett Packard Enterprise Company

import { AuthConsumer } from '../providers/AuthProvider';

export default function Logout() {
  return (
    <AuthConsumer>
      {({ logout }) => {
        logout();
        return null;
      }}
    </AuthConsumer>
  );
}

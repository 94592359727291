// Copyright 2021-2024 - Hewlett Packard Enterprise Company
import { useEffect, useState } from 'react';
import { throttle } from 'throttle-debounce';
import { off, on } from '../utils/eventHelpers';

const defaultEvents = [
  'mousemove',
  'mousedown',
  'resize',
  'keydown',
  'touchstart',
  'wheel',
];

const oneMinute = 60000;

export default function useIdle(
  ms = oneMinute,
  initialState = false,
  events = defaultEvents,
) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let mounted = true;
    let timeout;
    let localState = state;
    const set = newState => {
      if (mounted) {
        localState = newState;
        setState(newState);
      }
    };

    /**
     * Throttle execution of a function.
     * @param  {number}    delay
     * A zero-or-greater delay in milliseconds.
     * @param  {Function}  callback
     * A function to be executed after delay milliseconds.
     * @returns {Function}  A new, throttled, function.
     */

    const onEvent = throttle(50, () => {
      if (localState) {
        set(false);
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => set(true), ms);
    });

    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };
    for (let i = 0; i < events.length; i++) {
      on(window, events[i], onEvent);
    }
    on(document, 'visibilitychange', onVisibility);

    timeout = setTimeout(() => set(true), ms);

    return () => {
      mounted = false;

      for (let i = 0; i < events.length; i++) {
        off(window, events[i], onEvent);
      }
      off(document, 'visibilitychange', onVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms, events]);

  return state;
}

// Copyright 2024 - Hewlett Packard Enterprise Company
// Copyright 2021, 2024 - Hewlett Packard Enterprise Company

/**
 *
 * @param {Window | Document | HTMLElement | EventTarget} obj DOM elements
 * @param  {...Events} args Events to add to Event Listener
 */
export function on(obj = null, ...args) {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...args);
  }
}

/**
 *
 * @param {Window | Document | HTMLElement | EventTarget} obj DOM elements
 * @param  {...Events} args Events to remove from Event Listener
 */
export function off(obj = null, ...args) {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...args);
  }
}

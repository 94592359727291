// Copyright 2021-2024 - Hewlett Packard Enterprise Company
import { useHistory } from 'react-router-dom';
import { AuthConsumer } from '../providers/AuthProvider';
import { REDIRECT_URI_KEY } from '../constants/authConst';

export default function AuthCallback() {
  const history = useHistory();
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback()
          .then(() => {
            const redirectTo =
              window.sessionStorage.getItem(REDIRECT_URI_KEY) || '/';
            window.sessionStorage.removeItem(REDIRECT_URI_KEY);
            history.replace(redirectTo);
          })
          .catch(() => {
            // TODO: Possibly display an error message with a link to GLP login page.
            // eslint-disable-next-line no-console
            console.log('Unable to authenticate user. Redirect to CCS login');
          });
        return <span>Authenticating ...</span>;
      }}
    </AuthConsumer>
  );
}

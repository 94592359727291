// Copyright 2022-2024 - Hewlett Packard Enterprise Company
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import './i18n';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import './grommet-leaflet.css';

import { Box } from 'grommet';
import AuthCallback from './auth/AuthCallback';
import Login from './auth/Login';
import Logout from './auth/Logout';
import AuthProvider, { AuthConsumer } from './providers/AuthProvider';
import { REDIRECT_URI_KEY } from './constants/authConst';

import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import LoadingSpinnerWithText from './components/LoadingSpinner/LoadingSpinnerWithText';

// lazy loading incorrectly flags this rule
// eslint-disable-next-line react-refresh/only-export-components
const App = lazy(() => import('./App'));

const spinner = (
  <Box align="center" pad="small">
    <LoadingSpinner size="medium" />
  </Box>
);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter basename="/">
        <Switch>
          <Route path={['/auth', '/login', '/logout']}>
            <Switch>
              <Route exact path="/auth" component={AuthCallback} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
            </Switch>
          </Route>
          <Route>
            <Suspense
              fallback={
                <Box
                  height="xsmall"
                  margin={{ top: 'small' }}
                  width="100%"
                  align="center"
                  justify="center"
                  alignSelf="center"
                >
                  <LoadingSpinnerWithText
                    label="Loading..."
                    orientation="horizontal"
                  />
                </Box>
              }
            >
              <AuthConsumer>
                {({ isAuthenticated, token }) => {
                  if (isAuthenticated()) {
                    return token ? <App /> : spinner;
                  }
                  sessionStorage.setItem(
                    REDIRECT_URI_KEY,
                    window.location.pathname,
                  );
                  return <Login />;
                }}
              </AuthConsumer>
            </Suspense>
          </Route>
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
